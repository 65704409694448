import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';

import { useStore } from '../../../hooks/useStore';

const EnableDisableSeller = () => {
  const [loading, setLoading] = useState(false);
  const {
    userStore: { displayedUser, updateUser },
  } = useStore();

  const handleEnableDisableSeller = async (enable: boolean) => {
    try {
      setLoading(true);
      await updateUser(displayedUser.id, {
        sellerEnabled: enable,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {displayedUser.sellerEnabled ? (
        <Button
          w={'100%'}
          py="25px"
          onClick={() => handleEnableDisableSeller(false)}
          disabled={loading}
          isLoading={loading}
        >
          Disable Seller
        </Button>
      ) : (
        <Button
          w={'100%'}
          py="25px"
          color="green.400"
          onClick={() => handleEnableDisableSeller(true)}
          disabled={loading}
          isLoading={loading}
        >
          Enable Seller
        </Button>
      )}
    </>
  );
};

export default observer(EnableDisableSeller);
