export const endpoints = {
  users: {
    base: '/admin/users',
    checkUsername: '/admin/users/check-username',
    checkEmail: '/admin/users/check-email',
    byId: (id: string) => `/admin/users/${id}`,
    enableDisable: (id: string) => `/admin/users/${id}/enable-disable-seller`,
    approve: (id: string) => `/admin/users/${id}/approve`,
    reject: (id: string) => `/admin/users/${id}/reject`,
    sellerFee: (id: string) => `/admin/users/${id}/seller-fee`,
    featureById: (id: string) => `/admin/users/${id}/feature`,
    username: (id: string) => `/admin/users/${id}/username`,
    email: (id: string) => `/admin/users/${id}/email`,
  },
  listings: {
    base: '/admin/listings',
    active: '/admin/listings/active',
    sold: '/admin/listings/sold',
    soldById: (id: string) => `/admin/listings/sold/${id}`,
    activeById: (id: string) => `/admin/listings/active/${id}`,
    orderById: (id: string) => `/admin/listings/order/${id}`,
    cancelById: (id: string) => `/admin/listings/cancel-order/${id}`,
    featureById: (id: string) => `/admin/listings/feature/${id}`,
  },
  statistics: {
    base: '/admin/statistics',
  },
  subcategory: {
    base: '/admin/subCategory',
  },
  ratings: {
    base: '/admin/ratings',
    all: '/admin/ratings/all',
    delete: (id: string) => `/admin/ratings/soft-delete/${id}`,
  },
  chat: {
    base: '/admin/chat',
    allChats: '/admin/chat/all-chats',
    deleteChat: (id: string) => `/admin/chat/soft-delete-chat/${id}`,
    allMessages: '/admin/chat/all-messages',
    deleteMessage: (id: string) => `/admin/chat/soft-delete-message/${id}`,
  },
  auctionMessages: {
    base: '/admin/auction-messages',
    active: '/admin/auction-messages/active',
    delete: (id: string) => `/admin/auction-messages/soft-delete/${id}`,
  }
};
