import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
  VStack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { FaEdit } from 'react-icons/fa';
import { AddressType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { useStore } from '../../hooks/useStore';
import DefaultProfile from '../../assets/png/default-profile.png';
import ConfirmationModal from '../modals/ConfirmationModal';
import { Colors } from '../../theme/colors';

import InfoDisplay from './InfoDisplay';
import EnableDisableSeller from './user/EnableDisableSeller';
import ReviewUserRequest from './user/ReviewUserRequest';

type UserDetailsProps = {
  onDelete: () => Promise<void>;
};

const UserDetails = ({ onDelete }: UserDetailsProps) => {
  const {
    userStore: {
      displayedUser,
      checkUsername,
      checkEmail,
      updateUser,
    },
  } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingSellerFee, setIsEditingSellerFee] = useState(false);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [sellerFee, setSellerFee] = useState(displayedUser.sellerFee);
  const [username, setUsername] = useState(displayedUser.userName || '');
  const [email, setEmail] = useState(displayedUser.email || '');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const textColor = useColorModeValue(
    Colors.light.fadedText,
    Colors.dark.fadedText,
  );

  const userInfo = [
    { title: 'Amount Spent:', value: displayedUser.amountSpent || 'No data' },
    { title: 'Amount Sold:', value: displayedUser.amountSold || 'No data' },
    { title: 'Signed Up Date:', value: displayedUser.createdAt || 'No data' },
    {
      title: 'Seller stripe account id:',
      value: displayedUser.stripeUserId || 'No data',
    },
    {
      title: 'Seller customer account id:',
      value: displayedUser.stripeCustomerId || 'No data',
    },
    {
      title: 'Payment Failed Strikes:',
      value: displayedUser.paymentFailedStrikes || 'No data',
    },
  ];

  const handleDelete = async () => {
    await onDelete();
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleEditSellerFee = () => {
    setIsEditingSellerFee(true);
  };

  const handleSaveSellerFee = async () => {
    try {
      setLoading(true);
      await updateUser(displayedUser.id, {
        sellerFee,
      });
      setIsEditingSellerFee(false);
      toast({
        title: 'Success',
        description: 'Seller fee updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error',
        description: 'Failed to update seller fee',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSellerFeeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSellerFee(parseInt(event.target.value));
  };

  const handleEditUsername = () => {
    setIsEditingUsername(true);
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSaveUsername = async () => {
    try {
      setLoading(true);
      const checkIfUsernameExists = await checkUsername(username);

      if (checkIfUsernameExists.exists) {
        toast({
          title: 'Error',
          description: 'Username already exists',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      await updateUser(displayedUser.id, {
        userName: username,
      });
      setIsEditingUsername(false);
      toast({
        title: 'Success',
        description: 'Username updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error',
        description: 'Failed to update username',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveEmail = async () => {
    try {
      setLoading(true);
      const checkIfEmailExists = await checkEmail(email);

      if (checkIfEmailExists.exists) {
        toast({
          title: 'Error',
          description: 'Email already exists',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      await updateUser(displayedUser.id, {
        email,
      });
      setIsEditingEmail(false);
      toast({
        title: 'Success',
        description: 'Email updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error',
        description: 'Failed to update email',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const shippingInfo = useMemo(() => {
    if (
      !displayedUser.shippingInfo ||
      displayedUser.shippingInfo.length === 0
    ) {
      return null;
    }

    // for older users that might accidentally have multiple addresses of the same type
    const latestDefaultAddress = [...displayedUser.shippingInfo]
      .reverse()
      .find((info) => info.type === AddressType.DEFAULT);

    const latestReturnAddress = [...displayedUser.shippingInfo]
      .reverse()
      .find((info) => info.type === AddressType.RETURN_ADDRESS);

    return { latestDefaultAddress, latestReturnAddress };
  }, [displayedUser.shippingInfo]);

  const renderShippingInfo = (info: (typeof displayedUser.shippingInfo)[0]) => (
    <VStack align="start" spacing={1} key={info.type}>
      <Text fontWeight="bold">
        {info.type === AddressType.DEFAULT
          ? 'Default Address'
          : 'Return Address'}
      </Text>
      <Text>{`${info.firstName} ${info.lastName}`}</Text>
      <Text>{info.addressLineOne}</Text>
      {info.addressLineTwo && <Text>{info.addressLineTwo}</Text>}
      <Text>{`${info.city}, ${info.state} ${info.zipCode}`}</Text>
      <Text>{info.country}</Text>
      <Text>{`${displayedUser.countryCode} ${displayedUser.phoneNumber}`}</Text>
    </VStack>
  );

  return (
    <VStack align="center" spacing={4}>
      <Avatar size="xl" src={displayedUser.avatar || DefaultProfile} />
      <HStack>
        {isEditingUsername ? (
          <>
            <Input value={username} onChange={handleUsernameChange} />
            <Button onClick={handleSaveUsername} isLoading={loading}>
              Save
            </Button>
          </>
        ) : (
          <Flex align="center" gap={4} mb={4}>
            <Heading>{displayedUser.userName || 'No username'}</Heading>
            <IconButton
              aria-label="Edit username"
              icon={<FaEdit />}
              onClick={handleEditUsername}
            />
          </Flex>
        )}
      </HStack>
      <Box
        display="flex"
        flex={1}
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text flex={1}>Email:</Text>
        {isEditingEmail ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Input flex={1} value={email} onChange={handleEmailChange} />
            <Button onClick={handleSaveEmail} isLoading={loading} ml={2}>
              Save
            </Button>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
            w="100%"
          >
            <Text flex={1} color={textColor}>
              {displayedUser.email || 'No email'}
            </Text>
            <IconButton
              aria-label="Edit email"
              icon={<FaEdit />}
              onClick={handleEditEmail}
              ml={2}
            />
          </Box>
        )}
      </Box>

      {userInfo.map((info, index) => (
        <InfoDisplay key={index} title={info.title} value={info.value} />
      ))}
      {displayedUser.stripeUserId && (
        <HStack w="100%" justify="center" p={2} gap={10}>
          <Text fontWeight="bold">Seller Fee:</Text>
          {isEditingSellerFee ? (
            <>
              <Select value={sellerFee} onChange={handleSellerFeeChange}>
                {Array.from({ length: 26 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}%
                  </option>
                ))}
              </Select>
              <Button onClick={handleSaveSellerFee} isLoading={loading}>
                Save
              </Button>
            </>
          ) : (
            <HStack>
              <Text pr={10}>{sellerFee}%</Text>
              <IconButton
                aria-label="Edit seller fee"
                icon={<FaEdit />}
                onClick={handleEditSellerFee}
              />
            </HStack>
          )}
        </HStack>
      )}
      {shippingInfo && (
        <VStack
          w="100%"
          align="start"
          spacing={4}
          p={4}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Heading size="md">Shipping Information</Heading>
          {shippingInfo.latestDefaultAddress && (
            <Box>
              {renderShippingInfo(shippingInfo.latestDefaultAddress)}
              <Divider my={2} />
            </Box>
          )}
          {shippingInfo.latestReturnAddress &&
            renderShippingInfo(shippingInfo.latestReturnAddress)}
        </VStack>
      )}
      {displayedUser.pendingRequest && <ReviewUserRequest />}
      {displayedUser.stripeUserId && <EnableDisableSeller />}
      <Button onClick={handleClick} w="100%" bg="red.400" py="25px">
        Delete user
      </Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={'Delete user?'}
        subtitle={'This action cannot be undone'}
        cancelActionLabel={'Cancel'}
        confirmActionLabel={'Delete'}
        onCancel={handleModalClose}
        onConfirm={handleDelete}
      />
    </VStack>
  );
};

export default observer(UserDetails);
