import { endpoints } from '../constants/endpoints';
import { PaginatedQueryDto, UserDto } from '../constants/types';

import { BaseApi } from './index';

export class UserApi extends BaseApi<UserDto> {
  constructor() {
    super(endpoints.users.base);
  }

  async getUsers(query: Partial<PaginatedQueryDto>) {
    return this.fetch(endpoints.users.base, { params: query });
  }

  async deleteUser(id: string) {
    return this.delete(endpoints.users.byId(id));
  }

  async getUser(id: string) {
    return this.getById(id);
  }

  async approveSeller(id: string) {
    return this.patch(endpoints.users.approve(id), {});
  }

  async rejectSeller(id: string) {
    return this.patch(endpoints.users.reject(id), {});
  }

  async checkUserName(userName: string) {
    return this.post(endpoints.users.checkUsername, { userName });
  }

  async checkEmail(email: string) {
    return this.post(endpoints.users.checkEmail, { email });
  }

  async updateUser(id: string, updateDto: Partial<UserDto>) {
    return this.patch(endpoints.users.byId(id), updateDto);
  }
}
